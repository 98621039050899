import React from 'react';

export default function TituloMini(props) {

    return(
    <p style={{
        textAlign: 'left',
        fontSize: '0.9rem',
        fontWeight: '500'
        }}>
        { props.titulo }<br />
        <span style={{
            fontSize: '0.8rem', 
            fontWeight: '400',
            color: '#808080',
            letterSpacing: '0.5px'
        }}>
            {props.children}
        </span>
    </p>
    )

}
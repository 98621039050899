import React from 'react';

import '../../assets/css/HeaderVista.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';


export default function HeaderVista(props) {

    return (
        <Container className="Headervista" component="main" maxWidth="lg">
            <Grid container>
                {
                    props.start &&
                    <Grid className="start" item xs={12} md={1} >
                        {props.start}
                    </Grid>
                }
                <Grid className="titulo" item xs={12} md={(props.start ? 7 : 8)} >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '15px 0px',
                        fontWeight: '700',
                    }} >
                        {props.icon}
                        <span style={{ marginLeft: '10px', fontSize: '1.5rem' }}>{props.titulo}</span>
                    </div>
                </Grid>
                <Grid className="end" item xs={12} md={4} >
                    {props.end}
                </Grid>
            </Grid>
        </Container>
    )

}
import { createSlice } from "@reduxjs/toolkit"


export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: {
        open: false,
        message: 'Esto es un mensaje de prueba',
        severity: 'info' //error, warning, info, success
    },
    reducers: {
        openFeedback: (state, action) => {
            state.severity = (action.payload.severity ? action.payload.severity : 'error') 
            state.message = action.payload.message
            state.open = true
        },
        closeFeedback: (state, action) => {
            state.open = false
        }
    }
});

export const { openFeedback, closeFeedback } = feedbackSlice.actions

export const getFeedbackOptions = (state) => state.feedback


import React from 'react';

import '../../assets/css/Switch.css';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

export default function SwitchInput(props) {

    return (
        <>
        <FormControlLabel
            control={
                <Switch
                    checked={props.value}
                    onChange={(e) => props.onChange(!props.value)}
                    disabled={props.disabled}
                />
            }
            label={props.label}
        />
        <FormHelperText>{props.helperText}</FormHelperText>
        </>
    )

}
import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux'

import { openFeedback } from '../../store/slices/feedback'
import { useRemoveDocMutation } from '../../store/api/endpoints/deleteApiSlice'


import Loading from '../elementos/Loading';
import Input from '../elementos/Input';
import ButtonIcon from '../elementos/ButtonIcon';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


export default function ConfirmDelete(props) {

    const dispatch = useDispatch()

    const [
        deleteDocumento,
        {
            data: responseDeleteDocumento,
            isUninitialized: isUninitializedDeleteDocumento,
            isLoading: isLoadingDeleteDocumento,
            isSuccess: isSuccessDeleteDocumento,
            isError: isErrorDeleteDocumento,
            error: errorDataDeleteDocumento
        }
    ] = useRemoveDocMutation()

    const [openDialog, setOpenDialog] = useState(false)
    const [inputError, setInputError] = useState(false)
    const [inputValue, setInputValue] = useState('')

    const navigate = useNavigate()

    const handleOpen = () => {
        setInputValue("")
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    const handleChangeInputValue = (value) => {

        if (value === 'eliminar') {
            setInputError(false)
        } else {
            setInputError(true)
        }

        setInputValue(value)

    }

    const handleConfirmDelete = () => {

        if (inputValue === 'eliminar') {

            deleteDocumento({url: props.httpDelete})

        }

    }

    useEffect(() => {

        if (isSuccessDeleteDocumento) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha eliminado el registro!"
            }))

            navigate(props.returnUrl)

        }

        if (isErrorDeleteDocumento) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataDeleteDocumento.data.message
            }))
        }

    }, [
        isSuccessDeleteDocumento,
        isErrorDeleteDocumento,
    ])


    return (
        <>
            {
                props.soloIcono &&
                <ButtonIcon color="error" handleClick={handleOpen} size={props.size ? props.size : "small"} variant={props.variant ? props.variant : "contained"} noLink startIcon={<DeleteOutlineIcon />} />
            }
            {
                !props.soloIcono &&
                <Button type="button" onClick={handleOpen} size={props.size ? props.size : "small"} variant={props.variant ? props.variant : "contained"} color="error" startIcon={<DeleteOutlineIcon />} >
                    Eliminar
                </Button>
            }

            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>¿Eliminar registro?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro que deseas eliminar el registro?
                        <br />Por favor ingresa la palabra <b>eliminar</b> para continuar.
                    </DialogContentText>
                    <Input
                        type="text"
                        fullWidth
                        value={inputValue}
                        variant="standard"
                        error={inputError}
                        onChange={handleChangeInputValue}
                    />
                </DialogContent>
                {
                    !isLoadingDeleteDocumento &&
                    <DialogActions>
                        <Button variant="contained" color="neutral" onClick={handleClose}>Cancelar</Button>
                        <Button variant="contained" color="error" onClick={handleConfirmDelete} startIcon={<DeleteOutlineIcon />}>Eliminar</Button>
                    </DialogActions>
                }
                {
                    isLoadingDeleteDocumento &&
                    <DialogActions>
                        <Loading />
                    </DialogActions>
                }

            </Dialog>
        </>
    )

}
import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import '../../assets/css/ButtonIcon.css';

export default function ButtonIcon(props) {

    const styles = {}
    if (props.marginRight) styles.marginRight = '5px';


    if (props.noLink) {
        return (
            <Button
                onClick={props.handleClick}
                className={
                    "ButtonIcon " +
                    (props.children && props.startIcon ? ' hasChildrenIconStart ' : '') +
                    (props.children && props.endIcon ? ' hasChildrenIconEnd ' : '')
                }
                variant={props.variant}
                size={props.size}
                color={props.color}
                startIcon={(props.startIcon ? props.startIcon : '')}
                endIcon={(props.endIcon ? props.endIcon : '')}
                style={styles}
                disabled={props.disabled}
            >
                {props.children}
            </Button>
        )
    } else {
        return (
            <Link to={(props.to && !props.disabled ? props.to : '#')} >
                <Button
                    className={
                        "ButtonIcon " +
                        (props.children && props.startIcon ? ' hasChildrenIconStart ' : '') +
                        (props.children && props.endIcon ? ' hasChildrenIconEnd ' : '')
                    }
                    variant={props.variant}
                    size={props.size}
                    color={props.color}
                    startIcon={(props.startIcon ? props.startIcon : '')}
                    endIcon={(props.endIcon ? props.endIcon : '')}
                    style={styles}
                    disabled={props.disabled}
                >
                    {props.children}
                </Button>
            </Link>
        )
    }



}
import React from 'react';

import '../../assets/css/Input.css';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function Select(props) {

    return (
        <TextField
            select
            sx={{
                color: 'red',
                marginTop: '15px'
            }}
            label={props.label}
            value={props.value}
            disabled={props.disabled}
            onChange={(e) => props.onChange(e.target.value)}
            helperText={props.helperText}
            fullWidth
            size={(props.size ? props.size : '')}
            required={props.required}
            type={props.type}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {props.icon}
                    </InputAdornment>
                ),
            }}
        >
            {props.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    )

}
import { React } from 'react';
import { Link } from "react-router-dom";


import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import '../../assets/css/DisplayInfoUl.css';


export default function DisplayInfoUl({ titulo, data, editar }) {

    return (
        <>
            <div className="DisplayInfoUl">
                <div className="row">
                    <div className="titulo">
                        {titulo}
                    </div>
                    {
                        editar &&
                        <div className="icono">
                            <Link to={editar.path}>
                                <IconButton size="small" >
                                    <EditIcon />
                                </IconButton>
                            </Link>
                        </div >
                    }
                </div>

                <ul>
                    {
                        data.map((elemento, key) => {
                            return (
                                <li key={key}>{elemento.index}: <span>{elemento.value}</span></li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    )

}
import { tiendasApi } from '../api'

export const sidebarDataApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getSidebarData: builder.mutation({
            query: token => ({
                url: 'self/sidebar-data',
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            })
        }),
    })
})

export const {
    useGetSidebarDataMutation,
} = sidebarDataApiSlice
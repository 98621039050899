import { tiendasApi } from '../api'

export const deleteApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        removeDoc: builder.mutation({
            query(data){
                const { url } = data
                return {
                    url: '/' + url,
                    method: 'DELETE',
                }
            }
        }),
    })
})

export const {
    useRemoveDocMutation,
} = deleteApiSlice
import { React, useEffect, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'
import MuiAlert from '@mui/material/Alert'
import { getFeedbackOptions, closeFeedback } from '../../store/slices/feedback'

function SlideTransition(props) {
    return <Slide {...props} direction="up" />
}

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function Feedback() {

    const dispatch = useDispatch()
    const feedbackOptions = useSelector(getFeedbackOptions)

    const handleClose = () => {
        dispatch(closeFeedback())
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={feedbackOptions.open}
                onClose={handleClose}
                TransitionComponent={SlideTransition}
                autoHideDuration={3000}
            >
                <Alert onClose={handleClose} severity={feedbackOptions.severity} sx={{ width: '100%' }}>
                    {feedbackOptions.message}
                </Alert>
            </Snackbar>
        </>
    )

}
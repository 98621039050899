import { React, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';


import axios from 'axios';

import '../../assets/css/Dropzone.css';

import Loading from './Loading';

import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function Dropzone(props) {

    const [estadoVista, setEstadoVista] = useState('cargado');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [mensajeError, setMensajeError] = useState('');

    const navigate = useNavigate();

    const dropzone_messages = {
        'file-too-large': 'El archivo es muy pesado. Excede los '+ (props.maxSize/1000) +'kb.',
        'file-invalid-type': 'El formato del archivo es inválido. Formatos permitidos: ' + props.formatosPermitidos,
        'too-many-files': 'Demasiados archivos elegidos. Máximos archivos permitidos: '+props.maxFiles,
    };

    const handleOnDropAccepted = (files) => {

        setEstadoVista('loading');

        let body = new FormData();
        body.append("file", files[0]);
        axios.put(props.url, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(res => {

                navigate(props.returnUrl)

            })
            .catch((error) => {
                console.log(error?.response?.data);
                setEstadoVista('error')
            });
    }

    const handleOnDropRejected = (e) => {
        //muestra el codigo de error por consola cuando rechaza un archivo
        //console.log(e[0].errors[0].code);
        setMensajeError(dropzone_messages[e[0].errors[0].code]);
        handleDialogOpen();
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: props.formatosPermitidos,
        maxFiles: props.maxFiles,
        maxSize: props.maxSize,
        onDropAccepted: handleOnDropAccepted,
        onDropRejected: handleOnDropRejected,
    });

    return (
        <section className="container" >
            {
                estadoVista === 'cargado' &&
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <CloudUploadIcon sx={{ fontSize: 40 }} />
                    <br />
                    Arrastra tu archivo aquí.
                </div>
            }
            {
                estadoVista === 'loading' &&
                <Loading />
            }

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <Alert severity="error">
                    {mensajeError}
                </Alert >
            </Dialog>


        </section>
    );

}
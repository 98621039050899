import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/css/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { Provider } from 'react-redux'
import { store } from './store'

import Feedback from './components/elementos/Feedback'

const theme = createTheme({
  palette: {
    dark: {
      main: '#212529',
      contrastText: '#fff',
      dark: '#212529',
    },
    neutral: {
      main: '#6c757d',
      contrastText: '#fff',
      dark: '#6c757d',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
        <Feedback />
        <div className="fondoLogo"></div>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

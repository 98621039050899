import { createSlice } from "@reduxjs/toolkit";

var sideBarInitialState = false;
if (window.innerWidth >= 768) {
    sideBarInitialState = true;
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        status: sideBarInitialState,
        sidebarData: [],
    },
    reducers: {
        changeStatusSidebar: (state, action) => {
            state.status = action.payload
        },
        setStateSidebarData: (state, action) => {
            state.sidebarData = action.payload
        },
    }
});

export const { changeStatusSidebar, setStateSidebarData } = sidebarSlice.actions;

export const getStatusSidebar = (state) => state.sidebar.status;
export const getStateSidebarData = (state) => state.sidebar.sidebarData;

